import en from 'i18n-iso-countries/langs/en.json'
import es from 'i18n-iso-countries/langs/es.json'
import fr from 'i18n-iso-countries/langs/fr.json'
import ja from 'i18n-iso-countries/langs/ja.json'
import ko from 'i18n-iso-countries/langs/ko.json'
import zh from 'i18n-iso-countries/langs/zh.json'

// TODO: 目前對話只先支援 英文, 法文, 西班牙文, 中文, 日文,.
export const LANGUAGE = [
  // {
  //   key: 'ar',
  //   value: 'ar',
  //   name: 'اللغة العربية',
  // },
  // {
  //   key: 'de',
  //   value: 'de',
  //   name: 'Deutsch',
  // },
  {
    key: 'en',
    value: 'en',
    name: 'English',
    countryLan: en,
  },
  {
    key: 'es',
    value: 'es',
    name: 'Español',
    countryLan: es,
  },
  {
    key: 'fr',
    value: 'fr',
    name: 'Français',
    countryLan: fr,
  },
  // {
  //   key: 'hi',
  //   value: 'hi',
  //   name: 'हिंदी',
  // },
  // {
  //   key: 'id',
  //   value: 'id',
  //   name: 'Bahasa Indonesia',
  // },
  // {
  //   key: 'it',
  //   value: 'it',
  //   name: 'Italiano',
  // },
  {
    key: 'ja',
    value: 'ja',
    name: '日本語',
    countryLan: ja,
  },
  {
    key: 'ko',
    value: 'ko',
    name: '한국어',
    countryLan: ko,
  },
  // {
  //   key: 'ms',
  //   value: 'ms',
  //   name: 'Bahasa Melayu',
  // },
  // {
  //   key: 'pl',
  //   value: 'pl',
  //   name: 'Polski',
  // },
  // {
  //   key: 'pt',
  //   value: 'pt',
  //   name: 'Português',
  // },
  // {
  //   key: 'ru',
  //   value: 'ru',
  //   name: 'Русский',
  // },
  // {
  //   key: 'th',
  //   value: 'th',
  //   name: 'ภาษาไทย',
  // },
  // {
  //   key: 'tr',
  //   value: 'tr',
  //   name: 'Türkçe',
  // },
  // {
  //   key: 'vi',
  //   value: 'vi',
  //   name: 'Tiếng Việt',
  // },
  // {
  //   key: 'zh-CN',
  //   value: 'zh-CN',
  //   name: '简体中文',
  // },
  {
    key: 'zh-TW',
    value: 'zh-TW',
    name: '繁體中文',
    countryLan: zh,
  },
]

export const locales = LANGUAGE.map(l => l.value)

export const DEFAULT_LANGUAGE = 'en'

type Resources = Record<string, { translation?: string; redirect?: string }>

export const LANGUAGE_RESOURCES: Resources = {
  en: {
    translation: 'en',
  },
  ar: {
    translation: 'ar',
  },
  de: {
    translation: 'de',
  },
  es: {
    translation: 'es',
  },
  fr: {
    translation: 'fr',
  },
  hi: {
    translation: 'hi',
  },
  id: {
    translation: 'id',
  },
  it: {
    translation: 'it',
  },
  ja: {
    translation: 'ja',
  },
  jp: {
    redirect: 'ja',
  },
  ko: {
    translation: 'ko',
  },
  ms: {
    translation: 'ms',
  },
  pl: {
    translation: 'pl',
  },
  pt: {
    translation: 'pt',
  },
  ru: {
    translation: 'ru',
  },
  th: {
    translation: 'th',
  },
  tr: {
    translation: 'tr',
  },
  vi: {
    translation: 'vi',
  },
  zh: {
    redirect: 'zh-TW',
  },
  'zh-CN': {
    translation: 'zh-CN',
  },
  'zh-Hans': {
    redirect: 'zh-CN',
  },
  'zh-TW': {
    translation: 'zh-TW',
  },
  'zh-Hant': {
    redirect: 'zh-TW',
  },
}

export const LANG_COOKIE = {
  name: 'NEXT_LOCALE',
  'Max-Age': 31557600, //Math.round(60 * 60 * 24 * 365.25) - 1 year,
  SameSite: 'lax',
} as const

export const LOCALE_COOKIE_NAME = LANG_COOKIE.name
export const LOCALE_HEADER_NAME = 'x-lang-tag'
export const LOCALE_QUERY_NAME = 'hl'
