'use client'

import { CDN_URL } from '@paladise/config/constants'
import { APP_STORE_URL$$, footerRows, PLAY_STORE_URL$$ } from 'config/constants'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { env } from 'store/server-context/static'
import { tv } from 'tailwind-variants'
import LanguageSelect from './LanguageSelect'
import Logo from './Logo'

const footerVariant = tv({
  base: 'text-md min768:px-12 w-full px-5 py-6',
  variants: {
    theme: {
      light: 'bg-background-1st text-label-l1',
      dark: 'text-label-still-l1 border-glass-special-separators bg-[--color-palup-black]',
    },
  },
})

interface IFooter {
  theme?: 'light' | 'dark'
  changePathname?: boolean
}

const Footer = ({ theme = 'light', changePathname }: IFooter) => {
  const t = useTranslations()

  const { row1, row2, legal } = footerRows(t, {
    consumer: env.CONSUMER_BASE_URL,
    enterprise: env.ENTERPRISE_BASE_URL,
  })

  return (
    <footer className={footerVariant({ theme })}>
      <div className="mx-auto flex max-w-[1080px] flex-wrap justify-between">
        <div className="max-min768:w-1/2 max-min768:order-[-1] max-min768:mb-8 flex flex-col gap-2">
          <Logo width={116} height={47} />
          <div>
            <LanguageSelect changePathname={changePathname} />
            <div suppressHydrationWarning>
              © {new Date().getFullYear()} PalUp
            </div>
          </div>
        </div>
        <div className="max-min768:w1/3 flex flex-col gap-2">
          {row1().map(item => (
            <Link
              key={item.text + item.href}
              href={item.href}
              title={item.text}
              data-tracker-click={item.trackerEventName}
              className="text-subtitle block"
            >
              {item.text}
            </Link>
          ))}
        </div>

        {/* Legal */}
        <div className="max-min768:w-1/3 flex flex-col gap-2">
          <h2 className="text-subtitle">{t('common_legal')}</h2>
          <div className="flex flex-col gap-1">
            {legal().map(item => (
              <Link
                key={item.text + item.href}
                href={item.href}
                title={item.text}
                data-tracker-click={item.trackerEventName}
                className="text-body block"
              >
                {item.text}
              </Link>
            ))}
          </div>
        </div>

        <div className="max-min768:w-1/3 flex flex-col gap-2">
          {row2().map(item => (
            <Link
              key={item.text + item.href}
              href={item.href}
              title={item.text}
              data-tracker-click={item.trackerEventName}
              className="text-subtitle block"
            >
              {item.text}
            </Link>
          ))}
        </div>

        <div className="max-min768:w-1/2 max-min768:order-[-1] max-min768:mb-8 max-min768:items-end flex flex-col gap-2">
          <a href={APP_STORE_URL$$} target="_blank" rel="noopener noreferrer">
            <img
              src={CDN_URL + `/common/images/app_store_white.svg`}
              alt="App Store"
              width="142"
              height="41"
            />
          </a>
          <a href={PLAY_STORE_URL$$} target="_blank" rel="noopener noreferrer">
            <img
              src={CDN_URL + `/common/images/google_play_white.svg`}
              alt="Google Play"
              width="142"
              height="41"
            />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
